import React, { useState } from 'react'
import axios from 'axios'
import video from '../images/videobg.mp4'

function QrGenerator() {
    const [url, setUrl] = useState('');
    const [qrImage, setQrImage] = useState('')

    const generateQrCode = (e) => {
        e.preventDefault();
        axios.post("https://qrcodeapi-zeta.vercel.app/api/scanQrcode", { url: url })
            .then(response => {
                setQrImage(response.data)
            })

    }

    return (
        <>
            <main className="outerContainer">
                <video autoPlay loop muted>
                    <source src={video} type="video/mp4" />
                </video>
                <h1>QR Code Generator</h1>
                <div className="box">
                    <div className="form">
                        <label for="qr">Enter your text or URL</label>
                        <input type="text" id="qrText" placeholder="instagram.com" onChange={(e) => setUrl(e.target.value)} value={url} />

                        {
                            url.length > 0 && qrImage
                                ?
                                <div className="qrImgBox">
                                    <a href={qrImage} download ><img src={qrImage} alt="QR Code" className='qrImage' /></a>
                                    <a href={qrImage} download ><button className='downloadBtn'>Downlaod QR Code</button></a>
                                </div>
                                :
                                null
                        }

                        <button onClick={generateQrCode}>Generate QR Code</button>
                    </div>
                </div>
            </main>
            <footer>
                <h2> 
                    <a href="https://shekharpandge.in" target="_blank"> Made By Shekhar</a>
                </h2>
            </footer>
        </>
    )
}

export default QrGenerator
